const STAGED_HANDHELD_VALUE = 'staged';

/**
 * @class ./scenes/VenueManagement/scenes/VenueDashboard/services/Matrix/Matrix
 */
class Matrix
{
    /**
     * Checks if handheld is assigned to the certain checkpoint.
     *
     * @param {String} status
     * @param {Object} handheldCheckpoint
     * @param {String} checkpointName
     *
     * @returns {Boolean}
     */
    isHandheldAssignedToCheckpoint = (status, handheldCheckpoint, checkpointName) =>
    {
        let isHandheldAssignedToCheckpoint = false;

        let isStaged = status === STAGED_HANDHELD_VALUE;
        let isReportedValue = (handheldCheckpoint.latestAdminValueApplied && handheldCheckpoint.reportedValue === checkpointName);
        let isRequestedValue = (!handheldCheckpoint.latestAdminValueApplied && handheldCheckpoint.adminRequestedValue === checkpointName);

        if (isStaged && (isReportedValue || isRequestedValue)) {
            isHandheldAssignedToCheckpoint = true;
        }

        return isHandheldAssignedToCheckpoint;
    };

    /**
     * Returns handheld show status.
     *
     * @param {Object} handheldShow
     *
     * @returns {Object}
     */
    getHandheldShowStatuses = (handheldShow) =>
    {
        let isChecked = false;
        let isForced = false;
        let isPendingIconShown = false;

        if (handheldShow.forcedActive === true) {
            isChecked = true;
            isForced = true;
        } else {
            if (handheldShow.latestAdminValueApplied === true) {
                isChecked = handheldShow.reportedValue;
            } else {
                isChecked = handheldShow.adminRequestedValue;
                isPendingIconShown = true;
            }
        }

        return {
            isChecked          : isChecked,
            isForced           : isForced,
            isPendingIconShown : isPendingIconShown
        };
    };

    /**
     * Returns handheld ticket type status.
     *
     * @param {Object} handheldShow
     * @param {Object} handheldTicketType
     *
     * @returns {Object}
     */
    getHandheldTicketTypeStatuses = (handheldShow, handheldTicketType) =>
    {
        let isChecked = false;
        let isPendingIconShown = false;
        if ((handheldShow?.latestAdminValueApplied ?? true) === true) {
            isChecked = handheldTicketType?.reportedValue ?? false;
        } else {
            isChecked = handheldTicketType?.adminRequestedValue ?? false;
            isPendingIconShown = true;
        }
        return {
            isChecked          : isChecked,
            isPendingIconShown : isPendingIconShown
        };
    };

    /**
     * Checks if all devices for certain checkpoint are turnned on.
     *
     * @param {Array} shows
     * @param {Object} checkpoints
     * @param {String} checkpointName
     */
    getCollapsedCheckpointShowsAndTicketTypesStatuses = (shows, checkpoints, checkpointName) => {
        const checkpointShowsStatuses = [];
      
        shows.forEach((show, showIndex) => {
            const checkpointShowStatus = {
                [show.id]: {
                    status: [],
                    forcedActive: show.forcedActive,
                    checkpoint: checkpointName
                },
                ticketTypes: []
            };

            checkpoints.forEach(checkpoint => {
                if (checkpoint.name === checkpointName) {
                    checkpoint.devices.forEach(device => {
                        device.showSwitches[showIndex] &&
                            checkpointShowStatus[show.id].status.push(device.showSwitches[showIndex].isChecked);
                    });
                }
            });

            show.ticketTypes.forEach((ticketType, ticketTypeIndex) => {
                const checkpointTicketTypeStatus = {
                    [ticketType.id]: {
                        status: [],
                        checkpoint: checkpointName
                    }
                };
                checkpoints.forEach(checkpoint => {
                    if (checkpoint.name === checkpointName) {
                        checkpoint.devices.forEach(device => {
                            var showSwitch = device.showSwitches.find((element) => element.show.id == show.id);
                            showSwitch && showSwitch.ticketTypeSwitches[ticketTypeIndex] &&
                            checkpointTicketTypeStatus[ticketType.id].status.push(showSwitch.ticketTypeSwitches[ticketTypeIndex].isChecked);
                        });
                    }
                });
        
                const checkpointTicketTypeHasFalseStatus = checkpointTicketTypeStatus[ticketType.id].status.includes(false);
                const checkpointTicketTypeHasTrueStatus = checkpointTicketTypeStatus[ticketType.id].status.includes(true);
            
                checkpointTicketTypeStatus[ticketType.id].status = !checkpointTicketTypeHasFalseStatus;
                checkpointTicketTypeStatus[ticketType.id].mixedStatus = checkpointTicketTypeHasFalseStatus && checkpointTicketTypeHasTrueStatus;

                checkpointShowStatus.ticketTypes.push(checkpointTicketTypeStatus);
            });
        
            const checkpointShowHasFalseStatus = checkpointShowStatus[show.id].status.includes(false);
            const checkpointShowHasTrueStatus = checkpointShowStatus[show.id].status.includes(true);
        
            checkpointShowStatus[show.id].status = !checkpointShowHasFalseStatus;
            checkpointShowStatus[show.id].mixedStatus = checkpointShowHasFalseStatus && checkpointShowHasTrueStatus;
        
            checkpointShowsStatuses.push(checkpointShowStatus);
        });
      
        return checkpointShowsStatuses;
    };

    /**
     * Returns dashboard matrix shows and tickettypes array.
     *
     * @param {Array} shows
     *
     * @returns {Array}
     */
    getMatrixShows = (shows) =>
    {
        let matrixShows = [];
        shows.map(show => {
            let showObject = {
                id          : show.id,
                name        : show.name,
                begin       : show.begin,
                ticketTypes : []
            };
            show.ticketTypes.map(ticketType => {
                let ticketTypeObject = {
                    id      : ticketType.id,
                    name    : ticketType.name
                };
                showObject.ticketTypes.push(ticketTypeObject);
            })
            matrixShows.push(showObject);

            return true;
        });

        return matrixShows;
    };

/**
 * Returns JSON data for venue dashboard matrix.
 *
 * @param {Object} venue
 * @param {Object} shows
 * @param {Object} checkpoints
 *
 * @returns {Object}
 */
    getDashboardMatrixData = (venue, shows, checkpoints) => {
        const matrixData = {
            shows: this.getMatrixShows(shows),
            checkpoints: [],
            totalNumberOfDevices: 0,
            checkpointsStatuses: {},
        };
    
        checkpoints.forEach((checkpoint) => {
            const checkpointObject = {
                name: checkpoint.name,
                devices: [],
            };
        
            if (venue.handhelds) {
                Object.entries(venue.handhelds).forEach(([handheldId, handheld]) => {
                    if (this.isHandheldAssignedToCheckpoint(handheld.stagingStatus, handheld.checkpoint, checkpoint.name)) {
                        const handheldObject = {
                            batteryLevel: handheld.batteryLevel,
                            wifiSignal: handheld.wifiSignal,
                            name: handheld.name,
                            id: handheldId,
                            showSwitches: [],
                        };
            
                        shows.forEach((show) => {
                            let deviceShow = Object.entries(handheld.shows).find((element) => element[0] == show.id)?.[1];
                            const switchShowObject = {
                                isChecked: deviceShow ? this.getHandheldShowStatuses(deviceShow).isChecked : false,
                                isForced: deviceShow ? this.getHandheldShowStatuses(deviceShow).isForced : false,
                                isPending: deviceShow ? this.getHandheldShowStatuses(deviceShow).isPendingIconShown : false,
                                show: show,
                                ticketTypeSwitches: []
                            };
                            let atLeastOneActivated = (deviceShow && deviceShow.ticketTypeActivations.find((element) => deviceShow.latestAdminValueApplied ? element.reportedValue === true : element.adminRequestedValue === true)) !== undefined;
                            show.ticketTypes.map((ticketType) => {
                                let deviceTicketType = deviceShow && deviceShow.ticketTypeActivations.find((element) => element.id === ticketType.id);
                                const switchTicketTypeObject = {
                                    isChecked: switchShowObject.isChecked ? (atLeastOneActivated ? this.getHandheldTicketTypeStatuses(deviceShow, deviceTicketType).isChecked : true) : false,
                                    isPending: this.getHandheldTicketTypeStatuses(deviceShow, deviceTicketType).isPendingIconShown,
                                    ticketType: ticketType,
                                };
                                switchShowObject.ticketTypeSwitches.push(switchTicketTypeObject);
                            });                            
                            handheldObject.showSwitches.push(switchShowObject);
                        });
            
                        checkpointObject.devices.push(handheldObject);
                        matrixData.totalNumberOfDevices++;
                    }
                });
            }
        
            matrixData.checkpoints.push(checkpointObject);
        
            matrixData.checkpoints.forEach((matrixCheckpoint) => {
                if (matrixCheckpoint.name === checkpoint.name) {
                    matrixCheckpoint.collapsedDeviceStatuses = this.getCollapsedCheckpointShowsAndTicketTypesStatuses(
                        shows,
                        matrixData.checkpoints,
                        checkpoint.name
                    );
                }
            });
        });
    
        return matrixData;
    };
}

export default Matrix;
