import React,{ Component } from 'react';
import PropTypes           from 'prop-types';
import { Switch }          from '../../../../../../../../../../themes/default/Form/components/Switch';
import DevicePlain         from '../../../../../../../../../../components/Device/DevicePlain';

import ExpandIcon   from './images/expand.png';
import CollapseIcon from './images/collapse.png';
import IconLoading  from './images/loading.gif';
import IconWarning  from './images/warning.svg';

/**
 * @class ./scenes/VenueManagement/scenes/VenueDashboard/components/Matrix/components/Checkpoints/components/Checkpoint/Checkpoint
 */
class Checkpoint extends Component
{
    /**
     * Returns switch value when checkpoint is expanded.
     *
     * @param {Boolean} value
     * @param {String} name
     */
    getExpandedCheckpointSwitchValue = (value, name) =>
    {
        if (name !== undefined) {
            this.props.activateOrDeactivateDevice(value, name);
        }
    };

    /**
     * Returns switch value when checkpoint is collapsed.
     *
     * @param {Boolean} value
     * @param {String} name
     */
    getCollapsedCheckpointSwitchValue = (value, name) =>
    {
        if (name !== undefined) {
            const showId = name.split('/')[0];
            const ticketTypeId = name.split('/')[1];
            const checkpoint = name.split('/')[2];
            if (value) {
                this.props.activateShowAndTicketTypeAll(showId, ticketTypeId, checkpoint);
            } else {
                this.props.deactivateShowAndTicketTypeAll(showId, ticketTypeId, checkpoint);
            }
        }
    };

    /**
     * Returns true if checkpoint is expanded, otherwise false.
     *
     * @param {String} checkpointName
     *
     * @returns {Boolean}
     */
    isCheckpointExpanded = (checkpointName) =>
    {
        return this.props.expandedCheckpoints.includes(checkpointName);
    };

    /**
     * Returns true if checkpoint is single, otherwise false.
     *
     * @returns {Boolean}
     */
    isCheckpointSingle = () =>
    {
        return this.props.checkpoint.devices.length === 1;
    };

    /**
     * Returns matrix column classes.
     *
     * @param {Number} index
     * @param {String} checkpointName
     *
     * @returns {String}
     */
    getMatrixColumnClasses = (index, checkpointName) =>
    {
        let classes = "matrix-col";
        if (index !== 0 && !this.isCheckpointExpanded(checkpointName)) {
            classes += " matrix-col--hidden";
        }

        return classes;
    };

    /**
     * Returns expand/collapse checkpoint icon.
     *
     * @param {Number} index
     * @param {String} checkpointName
     *
     * @returns {XML}
     */
    renderCheckpointIcon = (index, checkpointName) =>
    {
        if (index === 0 && this.props.checkpoint.devices.length > 1) {
            return (
                <img src={this.isCheckpointExpanded(checkpointName) ? CollapseIcon : ExpandIcon}
                     alt={this.isCheckpointExpanded(checkpointName) ? "Collapse" : "Expand"}
                     title={this.isCheckpointExpanded(checkpointName) ? "Collapse checkpoints" : "Expand checkpoints"}
                     onClick={() => this.props.toggleCheckpoint(checkpointName)}/>
            );
        }
    };

    /**
     * Returns certain device component regarding the conditions are met.
     *
     * @param {String} checkpointName
     * @param {Object} device
     * @param {Number} numberOfDevices
     *
     * @returns {XML}
     */
    renderDevice = (checkpointName, device,numberOfDevices) =>
    {
        let deviceComponent;
        if (this.isCheckpointExpanded(checkpointName) || this.isCheckpointSingle()) {
            deviceComponent = (
                <DevicePlain wireless={device.wifiSignal}
                             battery={device.batteryLevel}
                             name={device.name}/>
            );
        } else {
            deviceComponent = <DevicePlain plain numberOfDevices={numberOfDevices}/>;
        }

        return deviceComponent;
    };

    /**
     * Handles rendering of the Switch component when checkpoint is expanded.
     *
     * @param {Object} showSwitchConfig
     * @param {Object} ticketTypeSwitchConfig
     * @param {Object} device
     * @param {String} checkpointName
     *
     * @returns {XML}
     */
    renderExpandedCheckpointSwitch = (showSwitchConfig, ticketTypeSwitchConfig, device, checkpointName) =>
    {
        let className = '';
        let isChecked = false;
        let showId = showSwitchConfig.show.id;
        let ticketTypeId = ticketTypeSwitchConfig ? ticketTypeSwitchConfig.ticketType.id : '';
        let switchConfig = ticketTypeSwitchConfig ? ticketTypeSwitchConfig : showSwitchConfig;
        if (switchConfig.isChecked) {
            if (switchConfig.isForced) {
                className = 'forced';
            }
            isChecked = true;
        }

        const switchComponent = (
            <div>
                <Switch className={className}
                        name={showId + '/' + ticketTypeId + '/' + device.id + '/' + checkpointName}
                        label=""
                        getValue={this.getExpandedCheckpointSwitchValue} checked={isChecked}/>
                {
                    switchConfig.isPending ?
                        <img src={IconLoading} alt="Loading"/> :
                        null
                }
            </div>
        );

        return switchComponent;
    };

    /**
     * Handles rendering of the Switch component when checkpoint is collapsed.
     *
     * @param {String} showId
     * @param {String} ticketTypeId
     * @param {Object} config
     *
     * @returns {XML}
     */
    renderCollapsedCheckpointSwitch = (showId, ticketTypeId, config) =>
    {
        let warningIconStyle = {paddingBottom: "2px"};

        return (
            <div>
                <Switch className={config.forcedActive ? 'forced' : ''}
                        name={showId + '/' + ticketTypeId + '/' + config.checkpoint}
                        label=""
                        getValue={this.getCollapsedCheckpointSwitchValue} checked={config.status}/>
                {config.mixedStatus &&
                <img src={IconWarning}
                     alt="Warning"
                     title={"Not all devices are in the same status"}
                     style={warningIconStyle}
                />}
            </div>
        );
    };

    /**
     * Handles rendering of Switch components.
     *
     * @returns {XML}
     */
    renderSwitches = () =>
    {
        if (this.isCheckpointExpanded(this.props.checkpoint.name) || this.isCheckpointSingle()) {
            return this.props.device.showSwitches.map((showSwitchConfig, showIndex) => {
                return (
                    <div className="matrix-item matrix-item--group">
                        <div key={showIndex} className="matrix-item--show text-center">
                            {this.renderExpandedCheckpointSwitch(showSwitchConfig, null, this.props.device, this.props.checkpoint.name)}
                        </div>
                        {showSwitchConfig.ticketTypeSwitches.map((ticketTypeSwitchConfig, ticketTypeIndex) => {
                            return (
                                <div key={(showIndex * 10 + ticketTypeIndex)} className="matrix-item--tickettype text-center">
                                    {this.renderExpandedCheckpointSwitch(showSwitchConfig, ticketTypeSwitchConfig, this.props.device, this.props.checkpoint.name)}
                                </div>
                            )
                        })}
                    </div>
                );
            });
        } else {
            return this.props.checkpoint.collapsedDeviceStatuses.map((deviceStatus, showIndex) => {
                let showId = Object.entries(deviceStatus)[0][0];
                let isShowChecked = Object.entries(deviceStatus)[0][1];
                let ticketTypes = deviceStatus.ticketTypes;
                return (
                    <div className="matrix-item matrix-item--group">
                        <div key={showIndex} className="matrix-item--show text-center">
                            {this.renderCollapsedCheckpointSwitch(showId,'', isShowChecked)}
                        </div>
                        {deviceStatus.ticketTypes.map((ticketType, ticketTypeIndex) => {
                            let ticketTypeId = Object.entries(ticketType)[0][0];
                            let isTicketTypeChecked = Object.entries(ticketType)[0][1];
                            return (
                                <div key={(showIndex * 10 + ticketTypeIndex)} className="matrix-item--tickettype text-center">
                                    {this.renderCollapsedCheckpointSwitch(showId, ticketTypeId, isTicketTypeChecked)}
                                </div>
                            )
                        })}
                    </div>
                );
            })
        }
    };

    /**
     * @returns {XML}
     */
    render()
    {
        return (
            <div className={this.getMatrixColumnClasses(this.props.index, this.props.checkpoint.name)}>
                <div className="matrix-item matrix-item--header text-center">
                    <span>{this.props.checkpoint.name}</span>
                    {this.renderCheckpointIcon(this.props.index, this.props.checkpoint.name)}
                </div>
                <div className="matrix-item matrix-item--device text-center">
                    {this.renderDevice(this.props.checkpoint.name, this.props.device, this.props.checkpoint.devices.length)}
                </div>
                {this.renderSwitches()}
            </div>
        );
    }
}

Checkpoint.propTypes = {
    index                               : PropTypes.number.isRequired,
    checkpoint                          : PropTypes.object.isRequired,
    device                              : PropTypes.object.isRequired,
    toggleCheckpoint                    : PropTypes.func.isRequired,
    expandedCheckpoints                 : PropTypes.array.isRequired,
    activateOrDeactivateDevice          : PropTypes.func.isRequired,
    activateShowAndTicketTypeAll        : PropTypes.func.isRequired,
    deactivateShowAndTicketTypeAll      : PropTypes.func.isRequired
};

export default Checkpoint;
